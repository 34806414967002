import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import NewsletterTemplate from 'templates/newsletter';

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 1px solid #e4e5e8;
  & div {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px;
    & .desc {
      padding: 10px 0 15px;
      margin-bottom: 0;
    }
  }
`;

const Content = () => {
  const { timeline } = useStaticQuery(graphql`
    query {
      timeline: file(relativePath: { eq: "timeline.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <table className="content" cellSpacing="0" cellPadding="0" align="center" width="100%">
      <tbody>
        <tr>
          <td>
            <table className="text" cellSpacing="0" cellPadding="0">
              <tbody>
                <tr>
                  <td style={{ padding: '32px 40px 12px' }}>
                    <h1 style={{ margin: 0 }}>Hi,</h1>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '12px 40px' }}>
                    <p style={{ margin: 0 }}>
                      2019 was quite a year for LiveSession. Thank you for being with us!{' '}
                      <span role="img" aria-label="emoji">
                        🚀{' '}
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '12px 40px' }}>
                    <p style={{ margin: 0 }}>
                      Here’s what we’ve been up to and what we’ve learned:
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '28px 40px 8px' }}>
                    <Box>
                      <div>
                        <p className="head">
                          <strong>~5 million</strong>
                        </p>
                        <p className="desc">unique users tracked monthly</p>
                      </div>
                      <div>
                        <p className="head">
                          <strong>66 countries</strong>
                        </p>
                        <p className="desc">our customers came from</p>
                      </div>
                      <div>
                        <p className="head">
                          <strong>51 minutes</strong>
                        </p>
                        <p className="desc">average session length</p>
                      </div>
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '12px 40px' }}>
                    <p style={{ margin: 0, textAlign: 'center' }}>
                      We’ve introduced game-changing features throughout the year:
                    </p>
                    <Img
                      fluid={timeline.childImageSharp.fluid}
                      alt="timeline"
                      title="LiveSession's year"
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '12px 40px' }}>
                    <p
                      style={{
                        margin: 0,
                        textAlign: 'center',
                        borderTop: '1px solid #e4e5e8',
                        paddingTop: 40,
                      }}
                    >
                      We’re happy that our work was recognized in the industry. We’ve been awarded
                      the High Performer title by G2 – twice in a row!{' '}
                      <span role="img" aria-label="emoji">
                        🏅
                      </span>
                    </p>
                    <a
                      href="https://www.g2.com/products/livesession/reviews"
                      className="go-to-app"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: 'block',
                        margin: '40px auto 16px',
                        backgroundColor: '#0a4ed6',
                        padding: '10px 0',
                        fontWeight: 700,
                        color: '#fff',
                        borderRadius: '4px',
                        width: '188px',
                        textAlign: 'center',
                      }}
                    >
                      Leave a G2 review
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '12px 40px 40px' }}>
                    <p
                      style={{
                        margin: 0,
                        textAlign: 'center',
                        borderTop: '1px solid #e4e5e8',
                        paddingTop: 40,
                      }}
                    >
                      And that’s not all: FinancesOnline honored us with the Rising Star and a
                      Premium Usability Award{' '}
                      <span role="img" aria-label="emoji">
                        🏆
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '12px 40px' }}>
                    <p
                      style={{
                        margin: 0,
                        textAlign: 'center',
                      }}
                    >
                      We’re also happy to see that you enjoy reading the{' '}
                      <a
                        href="https://livesession.io/blog/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        LiveSession blog
                      </a>
                      . Our latest articles have been features by{' '}
                      <a
                        href="https://uxdesign.cc/7-psychological-principles-for-better-ux-3ab1620b123"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        UX Collective
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://blog.balsamiq.com/ux-ui-links-december-2019/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Balsamiq
                      </a>
                      . Stay tuned for more knowledge and insights!{' '}
                      <span role="img" aria-label="emoji">
                        🔎
                      </span>
                    </p>
                    <a
                      href="https://livesession.io/blog/"
                      className="go-to-app"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: 'block',
                        margin: '40px auto 0',
                        backgroundColor: '#0a4ed6',
                        padding: '10px 0',
                        fontWeight: 700,
                        color: '#fff',
                        borderRadius: '4px',
                        width: '188px',
                        textAlign: 'center',
                      }}
                    >
                      Check our blog
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '28px 40px 8px' }}>
                    <p className="wishes" style={{ margin: 0 }}>
                      Here&apos;s to an even better 2020! See you around,
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '8px 40px 32px' }}>
                    <p className="team" style={{ margin: 0 }}>
                      <strong>LiveSession Team</strong> 👋
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const Newsletter = NewsletterTemplate(Content);

export const frontmatter = {
  title: '2019 in review – thank you for using LiveSession!',
  description: '2019 was quite a year for LiveSession. Thank you for being with us!',
  date: '2020-01-01',
  link: '/newsletters/january-2020/',
};

export default () => <Newsletter {...frontmatter} />;
